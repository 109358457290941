import React from "react";
import QRScanner from "./QRScanner";

const QRScannerWrapper = ({ onScanComplete, onScanError }) => {
  const onScanSuccess = (data) => {
    console.log(data);
    onScanComplete(data);
  };

  //   const onScanError = (error) => {
  //     console.log(error);
  //   };

  return (
    <div className="min-h-screen grid grid-cols-2">
      <div className="col-span-1 p-10 flex flex-col items-center justify-center">
        <h1 className="text-2xl font-semibold text-center mb-10">
          Welcome to "scan.tatvacare.in/exercise"
        </h1>
        <p className="text-base mb-10">
          An online platform that helps to perform the exercise on laptop for
          convience. An online platform that helps to perform the exercise on
          laptop for convience.An online platform that helps to perform the
          exercise on laptop for convience.An online platform that helps to
          perform the exercise on laptop for convience.
        </p>
        <ul className="w-full">
          <li>
            <span className="font-semibold">Step 1:</span> Allow your
            laptop/system to access the camera if camera didn't open up.
          </li>
          <li>
            <span className="font-semibold">Step 2:</span> place your phone with
            QR infront of the camere
          </li>
          <li>
            <span className="font-semibold">Step 3:</span> Exercise screen will
            popups up.
          </li>
          <li>
            <span className="font-semibold">Step 4:</span> adjust the camera
            angle and start perfoming the exercise as per instructions
          </li>
          <li>
            <span className="font-semibold">Step 5:</span> Wait for the mobile
            to receive the updates with result.
          </li>
        </ul>
      </div>
      <div className="col-span-1 p-10 flex flex-col items-center justify-center">
        <h3 className="mb-10 text-center">
          Place the QR code shown on phone infront of laptop camera.
        </h3>
        <QRScanner onScanSuccess={onScanSuccess} onScanError={onScanError} />
      </div>
    </div>
  );
};

export default QRScannerWrapper;
