import React from "react";
import QrReader from "react-qr-scanner";

const QRScanner = ({ onScanSuccess, onScanError }) => {
  // const handleScan = (data) => {
  //   console.log(data);
  //   if (data) {
  //     onScanSuccess(data.text);
  //   }
  // };
  const handleScan = (data) => {
    if (data) {
      console.log("Data from QR Code:", data);
      try {
        // Adjust based on the actual data structure returned
        const jsonString = data.text || data.data || data; // Different libraries may use different fields
        console.log("Raw JSON string:", jsonString);
        const decodedData = JSON.parse(jsonString);
        console.log(decodedData);
        onScanSuccess(decodedData);
        // setQrData(decodedData);
      } catch (error) {
        console.error("Error decoding JSON data:", error);
        // setQrData("Invalid JSON data");
      }
    }
  };

  const handleError = (err) => {
    onScanError(err);
    console.error(err);
  };

  return (
    <QrReader
      delay={300}
      onError={handleError}
      onScan={handleScan}
      style={{ width: "100%" }}
    />
  );
};

export default QRScanner;
