import { Navigate, createBrowserRouter } from "react-router-dom";

import NotFound from "./screens/404";
import SelectOption from "./screens/SelectOption";
import ScanInstructions from "./screens/ScanInstructions";
import SelectPosture from "./screens/SelectPosture";
import FaceScan from "./screens/FaceScan";
import FaceScanV3 from "./screens/FaceScanV3";
import Exercise from "./screens/Exercise";
// import FaceScanV3 from "./components/Scan/FaceScanWrapper/FaceScanWrapper";
import FingerScan from "./screens/FingerScan";
import ScanResult from "./screens/ScanResult";
// import FaceScanTwo from "./screens/FaceScanTwo";
import ScanComplete from "./screens/ScanComplete";

export const router = createBrowserRouter([
  // { index: true, path: "/", element: <Navigate to="/select-option" replace /> },
  { path: "*", element: <NotFound /> },
  // { path: "select-option", element: <SelectOption /> },
  // { path: "scan-instructions", element: <ScanInstructions /> },
  // { path: "select-posture", element: <SelectPosture /> },
  // { path: "scan-result", element: <ScanResult /> },
  // { path: "face-scan", element: <FaceScan /> },
  { path: "/face-scan-v2", element: <FaceScanV3 /> },
  { path: "/exercise", element: <Exercise /> },
  // { path: "scan-complete", element: <ScanComplete /> },
  // { path: "finger-scan", element: <FingerScan /> },
  // { path: 'face-scan-v2', element: <FaceScanTwo /> },
]);
