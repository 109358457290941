import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { faceScan } from "careplix-web-algo";
import SignalStrengthIndicator from "../components/Scan/SignalStrengthIndicator";
import ScanFailed from "../components/Scan/ScanFailed";
import Analyzing from "../components/Scan/Analyzing";
import NotInScreen from "../components/Scan/NotInScreen";
import ShowMessage from "../components/Scan/ShowMessage";

import { request } from "../api/request";
import { ENDPOINTS } from "../api/endpoints";

import { ReactComponent as CloseCircle } from "../assets/icons/close-circle.svg";
import CareflixLoader from "../components/CareflixLoader";

const FaceScanTwo = ({
  userInfo,
  onComplete = () => {},
  onError = () => {},
}) => {
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "",
    timeElapsed: 0,
    isLightMode: false,
    fps: 0,
  });
  const [addScanLoader, setAddScanLoader] = useState(false);
  const [scanToken, setScanToken] = useState("");

  const getScanToken = useCallback(async () => {
    console.log("Getting token");
    try {
      const payload = {
        user_id: userInfo?.userId,
      };
      const response = await request(ENDPOINTS.CREATE_TOKEN, "POST", payload);

      if (response?.statusCode?.toString().startsWith("2")) {
        setScanToken(response.scan_token);
      } else {
        throw new Error(
          response?.message ?? "Error in Fetching the Scan Token"
        );
      }
    } catch (error) {
      console.error(error);
      setError("UNABLE_TO_GENERATE_SCAN_TOKEN");
    }
  }, [userInfo]);

  const handleOffline = () => {
    setError("DEVICE_OFFLINE");
  };

  useEffect(() => {
    console.log(addScanLoader, scanToken);
    if (scanToken.length > 0) {
      setAddScanLoader(true);
      faceScan.onScanFinish(
        async ({ raw_intensity, ppg_time, average_fps }) => {
          try {
            setAnalyzing(true);
            const payload = {
              app_version: userInfo?.appVersion,
              user_id: userInfo?.userId,
              employee_id: userInfo?.employeeId,
              ref_id: "",
              scan_token: scanToken,
              posture: userInfo?.posture ?? "resting", // Resting | Walking | Standing | Exercising
              dob: userInfo?.dob, // yyyy-MM-dd
              gender: userInfo?.gender, // Male | Female | Other
              metadata: {
                physiological_scores: {
                  height: userInfo?.height,
                  weight: userInfo?.weight,
                },
                ppg_time: ppg_time,
                raw_intensity: raw_intensity,
                device: navigator.platform.match(/iPhone|iPod|iPad/)
                  ? "RPPG_CAREPLIX_FACE_IOS"
                  : "RPPG_CAREPLIX_FACE_ANDROID",
                fps: average_fps,
              },
            };
            const resp = await request(ENDPOINTS.ADD_SCAN, "POST", payload);

            if (resp.statusCode?.toString().startsWith("2")) {
              onComplete && onComplete();
              setAddScanLoader(false);
            } else throw new Error(resp.message);
          } catch (err) {
            console.error(err);
            setError("ADD_SCAN_FAILED");
            setAddScanLoader(false);
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [scanToken]);

  useEffect(() => {
    if (error) {
      onError && onError(error);
    }
  }, [error, onError]);

  useEffect(() => {
    if (userInfo) {
      faceScan
        .startScan(60000, 60000, "/model", 3000, {
          color: "#fff",
        })
        .then(async () => {
          console.log("Scan Started");
          getScanToken();
        })
        .catch(console.error);
    }
  }, [userInfo, getScanToken]);

  useEffect(() => {
    faceScan.onFrame((fd) => setScanFrameData(fd));

    faceScan.onError((err) => {
      console.error(err);
      setError("SCAN_ERROR");
    });

    window.addEventListener("offline", handleOffline);

    return () => {
      faceScan.stopScan(true);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div className="relative h-screen bg-white">
      {error.length > 0 ? (
        <ScanFailed />
      ) : analyzing ? (
        <Analyzing />
      ) : (
        <>
          {
            <div className="relative h-full w-full">
              <video
                className="fixed bottom-12 left-8 w-px h-px bg-white/80"
                id="videoInput"
                autoPlay
                muted
                playsInline
              />
              <canvas
                id="canvasOutput"
                className="h-full w-full -scale-x-100"
              />
              {!faceScan.isFaceInView() && <NotInScreen />}
              {faceScan.canStop() && (
                <button
                  type="button"
                  onClick={() => faceScan.stopScan()}
                  className="fixed top-8 left-4 rounded-full px-3 py-1.5 text-xs text-white bg-[#ED1749]"
                >
                  Finish Scan
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  faceScan.stopScan(true);
                  setError("SCAN_CANCELLED");
                }}
                className="fixed top-4 right-4 bg-white rounded-full px-4 py-2 self-end text-xs font-medium flex items-center gap-2"
              >
                Cancel Scan
                <CloseCircle />
              </button>
              <div className="fixed bottom-10 inset-x-6 p-4 rounded-xl bg-white/80 flex flex-col">
                <h3 className="text-primary font-semibold">
                  {scanFrameData.type === "scan"
                    ? `${Math.round(
                        ((scanFrameData.timeElapsed - 20000) / 60000) * 100
                      )}% Completed...`
                    : `Calibration in progress...`}
                </h3>
                <h4 className="mt-1.5 text-primary text-xs">
                  <ShowMessage
                    type={scanFrameData.type}
                    timeElapsed={scanFrameData.timeElapsed}
                    isLightMode={scanFrameData.isLightMode}
                  />
                </h4>
                <div className="flex items-start justify-start">
                  <SignalStrengthIndicator fps={scanFrameData.fps} />
                </div>
              </div>
              {faceScan.isInitializing() && <CareflixLoader />}
            </div>
          }
        </>
      )}
    </div>
  );
};

export default FaceScanTwo;
