import { useState } from "react";

const useApi = (api = (params) => {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const mutate = async (params) => {
    setIsLoading(true);
    const response = await api(params);

    if (Number(response?.code) === 1) {
      setResponse(response);
      setIsLoading(false);
    } else {
      setError(response);
      setIsLoading(false);
    }
  };

  return {
    mutate,
    isLoading,
    response,
    error,
  };
};

export default useApi;
