import { request } from "./request";
import { ENDPOINTS } from "./endpoints";

const getActyv = {
  saveAssessmentResult: (payload) => {
    return request(ENDPOINTS.ADD_ASSESSMENT_RESULT, "POST", payload);
  },
  getAssessmentResult: (assessmentId) => {
    return request(`${ENDPOINTS.GET_ASSESSMENT_RESULT}/${assessmentId}`, "GET");
  },
  savePlanResult: (payload) => {
    return request(ENDPOINTS.ADD_PLAN_RESULT, "POST", payload);
  },
};

export default getActyv;
