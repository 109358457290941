import React, { useEffect, useState } from "react";
import "./styles.css"; // Add your Tailwind CSS styles here
import Iframe from "./Iframe";
import ExerciseComplted from "./ExerciseComplted";
import QRScannerWrapper from "./QRScannerWrapper";

import useApi from "../../hooks/useApi";
import getActyv from "../../api/get-actyv";
import { toast } from "react-toastify";

const Exercise = () => {
  const [steps, setSteps] = useState("NOT_STARTED"); // NOT_STARTED, ONGOING, COMPLETED, ERROR
  const [data, setData] = useState("");

  const {
    mutate: saveAssessmentResult,
    isLoading: isSaveAssessmentLoading,
    response: saveAssessmentResponse,
    error: saveAssessmentError,
  } = useApi(getActyv.saveAssessmentResult);
  const {
    mutate: savePlanResult,
    isLoading: isSavePlanLoading,
    response: savePlanResponse,
    error: savePlanError,
  } = useApi(getActyv.savePlanResult);

  useEffect(() => {
    if (saveAssessmentResponse?.code === 1) {
      setSteps("COMPLETED");
    } else if (saveAssessmentError) {
      toast.error("Something went wrong, Unable to save the Result!");
    }
  }, [saveAssessmentResponse, saveAssessmentError]);

  useEffect(() => {
    if (savePlanResponse?.code === 1) {
      if (savePlanResponse?.getrespresultresp?.status === 200) {
        if (savePlanResponse?.getrespresultresp?.assessmentResultDetails) {
          setSteps("COMPLETED");
          // type: "assessment",
        } else if (savePlanResponse?.getrespresultresp?.planResultDetails) {
          setSteps("COMPLETED");
          // type: "plan",
        }
      }
    } else if (savePlanError) {
      toast.error("Something went wrong, Unable to save the Result!");
    }
  }, [savePlanResponse, savePlanError]);

  const onScanSuccess = (data) => {
    setSteps("ONGOING");
    setData(data);
    console.log(data);
  };

  const onScanError = (error) => {
    console.log(error);
  };

  const handlePostMessage = (data) => {
    console.log("handlePostMessage", data);
    // setSteps('COMPLETED')
    const parsedData = JSON.parse(data);

    // status: "COMPLETE", "ERROR", "CRASH",
    // data: [{}, {}]
    if (!!parsedData) {
      if (parsedData?.status === "COMPLETE") {
        handleSubmit(parsedData?.data);
      } else if (parsedData?.status === "ERROR") {
        toast.error("Error while scanning the data");
        setSteps("NOT_STARTED");
      } else if (parsedData?.status === "CRASH") {
        toast.error("Something went wrong!");
        setSteps("NOT_STARTED");
      }
    }
  };

  console.log("data", data);

  const handleSubmit = async (assessmentResult) => {
    console.log("assessmentResult", assessmentResult);
    const payload = {
      userId: data?.otherParams?.userId,
      coachId: data?.otherParams?.coachId,
      routineData: assessmentResult,
    };
    if (data?.iFrameParams?.type === "assessment") {
      payload["assessmentId"] = data?.iFrameParams?.assessmentId;
      await saveAssessmentResult(payload);
    } else if (data?.iFrameParams?.type === "plan") {
      payload["planId"] = data?.iFrameParams?.planId;
      payload["sessionId"] = data?.otherParams?.sessionId;
      await savePlanResult(payload);
    }
  };

  if (steps === "NOT_STARTED") {
    return (
      <QRScannerWrapper
        onScanComplete={onScanSuccess}
        onScanError={onScanError}
      />
    );
  } else if (steps === "ONGOING") {
    return (
      <Iframe url={data?.iFrameUrl} handlePostMessage={handlePostMessage} />
    );
  } else if (steps === "COMPLTED") {
    return (
      <div className="min-h-screen grid grid-cols-2">
        <ExerciseComplted />
      </div>
    );
  }
};

export default Exercise;
