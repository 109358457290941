import React, { useState, useEffect } from "react";

const IframeComponent = ({ url, handlePostMessage }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [messageData, setMessageData] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      const expectedOrigin = new URL(url).origin;

      if (event.origin === expectedOrigin) {
        console.log("Message received from iframe:", event.data);
        setMessageData(event.data);
      } else {
        console.warn("Untrusted message origin:", event.origin);
      }

      handlePostMessage(event.data);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [url]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      {isLoading && (
        <div className="loading-indicator">
          <p>Loading...</p>
        </div>
      )}
      <div className="iframe-container w-full h-full">
        <iframe
          src={url}
          title="Scanned URL"
          className="w-full h-[800px] border border-gray-300 rounded"
          allow="camera; microphone; autoplay; encrypted-media; fullscreen"
          onLoad={() => setIsLoading(false)}
          allowFullScreen
          sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
        ></iframe>
      </div>
      {messageData && (
        <div className="message-data mt-4 p-4 bg-gray-100 border border-gray-300 rounded shadow">
          <h2 className="text-lg font-semibold">Data from iframe:</h2>
          <pre className="text-sm">{JSON.stringify(messageData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default IframeComponent;
