import { handleResponse } from "../utils/helper";
import { getEncryptedText } from "./hanlders";

export const request = async (url, method = "GET", payload) => {
  // Encrypt the payload only if it's not a GET request
  const requestPayload = method !== "GET" ? getEncryptedText(payload) : null;

  // Create a config object for fetch
  const config = {
    method: method,
    headers: {
      "api-key": process.env.REACT_APP_API_KEY,
      // Optionally include 'Content-Type' if you know the API expects it
      ...(method !== "GET" && { "Content-Type": "application/json" }), // or the appropriate content type for your encrypted text
    },
    // Only include the body if it's not a GET request
    ...(method !== "GET" && { body: requestPayload }),
  };

  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, config);
    const resp_json = await handleResponse(resp, true);
    return resp_json;
  } catch (error) {
    console.error("Request failed:", error);
    throw error;
  }
};
